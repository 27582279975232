@import './reset.css';
@media (prefers-reduced-motion: no-preference) {
  * {
    scroll-behavior: smooth;
  }
}

:root {
  --white-text: #fff;
  --black-text: #191919;
  --bg-pink: #de1e65;
  --bg-black: #111111;
  --bg-grey: #999999;
}

*,
a {
  /*font-family: Fixel, sans-serif;*/
}

html,
body {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
}

.short-title {
  color: #acacac;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  margin: 10px 0;
}

.catalog-wrapper {
  display: flex;
  gap: 20px;
}

.catalog-side {
  max-width: 320px;
  min-width: 320px;
}

.catalog-content {
  width: 100%;
}

.catalog-head {
  width: 100%;
  margin: 0 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 4px;
  min-height: 41px;
}

.catalog-head p {
  position: relative;
  float: left;
  color: #777777;
  font: 300 10px/20px 'Roboto';
  display: flex;
  align-items: center;
  gap: 3px;
}

.catalog-head b {
  font-size: 18px;
  color: #333;
}

.filter-mobile {
  display: none;
}

.filter-mobile-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
  margin-bottom: 25px;
}

.filter-mobile-list button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  cursor: pointer;
}

.btn__minus,
.btn__plus {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  cursor: pointer;
}


.home__link {
  width: 100%;

  color: #737373;
  font-size: 15px;
  font-weight: 300;
  font-family: Fixel, sans-serif;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .catalog-head {
    padding: 10px;
    flex-wrap:wrap;
    gap: 10px;
  }

  .catalog-head p {
    width: 100%;
  }

  .catalog-side {
    min-width: 240px;
    max-width: 240px;
  }
  .cart__total {
    margin: 0 12px 0 0;
  }
}

@media (max-width: 640px) {
  .catalog-side {
    display: none;
  }

  .catalog-head p {
    width: calc(100% - 50px);
  }

  .catalog-head > div {
    display: none;
  }

  .filter-mobile {
    display: block;
  }
}

@media (max-width: 420px) {
  .filter-mobile-list {
    gap: 10px;
  }

  .filter-mobile-list button {
    font-size: 12px;
  }

  .filter-mobile-list button svg {
    width: 12px;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
}
