.basket__section {
  width: 100%;
  padding-top: 10px;
}

.basket__wrapper {
  width: 100%;
  max-width: 965px;
  margin: 20px auto 40px;
  position: relative;
  display: block;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.basket__head {
  display: flex;
  align-items: center;
  height: 40px;
}

.basket__head p {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #555555;
}

.basket__head p:first-child {
  width: 60%;
  padding-left: 60px;
}

.basket__head p:nth-child(2) {
  width: 25%;
}
.basket__head p:last-child {
  width: 15%;
}

.basket__list {

}

.basket__item {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 32px 0;
  border-top: 1px solid #f9f9f9;
}

.delete__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete__btn img {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.basket__picture {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  width: 155px;
  min-width: 155px;
}

.basket__itemWrap {
  display: flex;
  width: 60%;
  gap: 5px;
}

.basket__picture img {
  width: 80px;
  height: auto;
}

.item__block {
  display: block;
}

.item__name {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #333333;
  margin: 0;
  text-decoration: none;
}

.item__price {
  background-color: #FFF8F0;
  padding: 8px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 16px 0 0 0;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  color: #333333;
}

.item__text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6D6D6D;
  margin: 5px 0 10px;
}

/*.item__quantity {*/
/*  width: 25%;*/
/*}*/

.cart__total {
  display: flex;
  width: 23%;
  min-width: 130px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ACACAC;
  margin: 0 0 0 10px;
  align-items: center;
  justify-content: space-between;
}

.item__quantity .cart__total {
  width: 220px;
  margin: 0;
}

.cart__total input {
  width: 120px;
  height: 38px;
  border: none;
  border-right: 1px solid #ACACAC;
  border-left: 1px solid #ACACAC;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #555555;
  border-radius: 0;
  padding: 0 14px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none; }
.item__total__price {
  width: 100%;
  margin-top:10px;
}

.item__total__price span {
  display: none;
}

.item__total__price p {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
}

.counterWrap {
  display: flex;
  width: 40%;
}

.cart__bottom {
  background: #fff;
  padding: 20px 32px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
}

.cart__description label {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #555555;
  margin-bottom: 10px;
}

.cart__description textarea {
  width: 100%;
  border: 1px solid #ACACAC;
  border-radius: 10px;
  min-height: 80px;
  outline: none;
  padding: 10px;
  resize: vertical;
}

.cart__result {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.result__left {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.result__right {
  padding: 32px 0;
}

.cart__sum {
  border: none;
  width: auto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: #333333;

  display: flex;
  /*width: 23%;*/
  min-width: 159px;
  height: 40px;
  border-radius: 4px;
  margin: 0 0 0 10px;
  align-items: center;
  justify-content: space-between;
}

.cart__sum p {
  padding-left: 45px;
}

.result__left button {
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  color: #333333;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 10px;
}

.send__btn {
  min-width: 240px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
  cursor: pointer;
  margin: 24px 0 0;
  background: #BB0D47;
  color: white;
  transition: all .25s ease;
}

.send__btn:hover {
  background: #DF5E89;
}

@media (max-width: 1155px) {
  .cart__total {
    min-width: 200px;
  }
  .cart__total input {
    padding: 0 10px;
    width: 120px;
  }
}

@media (max-width: 999px) {
  .basket__item {
    display: flex;
    flex-wrap: wrap;
  }
  .counterWrap {
    flex-direction: column;
    align-items: flex-end;
  }
  .basket__head p:last-child {
    display: none;
  }
  .item__total__price {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    align-items: baseline;
    padding: 10px;
  }
  .item__total__price p {
    width: auto;
  }
  .item__total__price span {
    display: block;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #7c7c7c;
  }
  .item__quantity {
    margin-right: 10px;
    width: auto;
  }
  .cart__total {
    margin: 0 12px 0 0;
  }
  .basket__head p:first-child {
    width: 80%;
  }
  .cart__description {
    width: auto;
    padding: 0 0 0 10px;
  }
  .result__left {
    padding: 40px 0 40px 10px;
  }
  .result__right {
    padding: 40px 0 40px 10px;
  }
}

@media (max-width: 770px) {
  .basket__head p:nth-child(2) {
    display: none;
  }
  .basket__head p:first-child {
    width: 100%;
  }
  .basket__item {
    flex-direction: column;
  }
  .basket__itemWrap, .counterWrap {
    width: 100%;
  }

  .counterWrap {
    flex-direction: row;
    justify-content: flex-start;
  }
}

@media (max-width: 639px) {
  .delete__btn {
    position: absolute;
    top: 5px;
    left: 5px;
  }
  .basket__picture {
    width:100px;
  }
  .basket__item {
    padding: 25px 10px 0;
  }
  .item__block {
    width: 60%;
  }
  .item__quantity {
    display: flex;
    margin: 0 10px 0 auto;
  }
  .cart__bottom {
    padding: 20px 10px;
  }
  .cart__description {
    padding: 0;
  }
  .cart__result {
    flex-wrap: wrap;
  }
  .result__left {
    padding: 20px 10px;
  }
  .result__right {
    padding: 20px 10px;
  }
}

@media (max-width: 570px) {
  .counterWrap {
    flex-wrap: wrap;
  }

  .item__quantity {
    margin: 15px 0 0;
  }

  .item__total__price {
    justify-content: flex-start;
  }

  .item__block {
    width: 100%;
  }

  .basket__itemWrap {
    flex-wrap: wrap;
  }
}