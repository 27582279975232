.header__mobile{
    display:none;
}

.btn__mobile_menu {
    display: block;
    width: 26px;
    height: 22px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.btn__mobile_menu span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fafafa;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0);
}

.btn__mobile_menu span:nth-child(2) {
    top: 9px;
}

.btn__mobile_menu span:nth-child(3) {
    top: 18px;
}

.mobileMenu {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0.5s;
    -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0.5s;
    transition: transform 0.5s 0s, visibility 0s 0.5s;
    pointer-events: none;
    background: rgba(0,0,0,.9);
    color: #fff;
}

.mobileMenu.open {
    visibility: visible;
    transform: translateY(0);
    -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0s;
    -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0s;
    transition: transform 0.5s 0s, visibility 0s 0s;
    pointer-events: initial;
}

.closeMenu {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    height: 50px;
    width: 50px;
    transition: all 0.2s linear 0s;
}

.closeMenu::after, .closeMenu::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    height: 20px;
    width: 2px;
    background: #fff;
    transition: all 0.2s linear 0s;
}

.closeMenu::after {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.closeMenu::before {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.closeMenu:hover::before {
    height: 15px;
    left: 60%;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transition: all 0.2s linear 0s;
}

.closeMenu:hover::after {
    height: 15px;
    left: 40%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transition: all 0.2s linear 0s;
}

.mobileMenuHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 5%;
}

.mobileMenuHead svg {
    display: block;
}

.mobileMenuList, .mobileMenuSubList {
    list-style-type: none;
    min-width: 100vw;
    padding-right: 15px;
    transition: transform 0.3s;
    overflow: auto;
    height: 100vh;
}

.mobileMenuList li {
    position: relative;
    padding: 12px 5%;
    transition: 0.2s;
    font-size: 17px;
    cursor: pointer;
}

 .mobileMenuList li:hover {
     color: #CC3366;
 }

.mobileMenuList li::before, .mobileMenuList li::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    background: #fff;
    height: 2px;
    width: 10px;
    right: 20px;
    transform-origin: 10px 50%;
    transition: all 0.2s linear 0s;
}

.mobileMenuList li::before {
    transform: rotate(45deg);
}

.mobileMenuList li::after {
    transform: rotate(-45deg);
}

.mobileMenuList li:hover::before {
    transform: rotate(30deg);
}

.mobileMenuList li:hover::after {
    transform: rotate(-30deg);
}

.mobileMenuWrapperList {
    overflow-x: hidden;
    display: flex;
}

.mobileMenuWrapperList.isSub .mobileMenuList,
.mobileMenuWrapperList.isSub .mobileMenuSubList {
    transform: translateX(-100%);
}

.mobileMenuSubList li a,
.goBack {
    transition: color 0.2s;
    text-transform: initial;
    padding: 12px 5%;
    color: #fff;
    display: block;
    font-size: 18px;
    width: 100%;
}

.mobileMenuSubList li a:hover,
.goBack:hover {
    color: #CC3366;
}

.goBack {
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    padding-left: 40px;
}

.goBack::before, .goBack::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 2px;
    width: 10px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: #fff;
    left: 20px;
    transform-origin: 0 50%;
}

.goBack::before {
    transform: rotate(45deg);
    transition: all 0.2s linear 0s;
}

.goBack:hover::before {
    transform: rotate(30deg);
    transition: all 0.2s linear 0s;
}

.goBack::after {
    transform: rotate(-45deg);
    transition: all 0.2s linear 0s;
}

.goBack:hover::after {
    transform: rotate(-30deg);
    transition: all 0.2s linear 0s;
}

@media (min-width: 771px) {
    .mobileMenu {
        display: none;
    }
}

@media (max-width: 770px) {

    .container__mobile{
        width: 100%;
        padding: 5px 10px;
        margin: 0 auto;
    }
    .header__mobile{
        display:block;
        background-color: var(--black-text);
        color: var(--white-text);
    }
    .header__mobile__wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header__mobile_left,
    .header__mobile_right{
        display:flex;
        align-items: center;
    }
    .header__mobile__logo{
        width: 70%;
        margin-left: 20px;
    }
    .header__mobile_right{
        gap: 15px
    }
    .header__mobile_right__link{
        display: inline-block;
        width: 20px;
        height: 20px;
    }
    .mobile__link__svg{
        width: 100%;
    }

}
