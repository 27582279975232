.header{
    padding: 10px 0;
    background-color: #191919;
    color: var(--white-text);
}
.header__nav{
    margin-bottom: 10px;
}
.header__nav,
.header__down{
    display: flex;
    gap: 15px;
    align-items: center;
}
.nav__link__list{
    display: flex;
    gap: 25px;
    padding-top: 15px;
    margin-left: 76px;
}
.nav__link{
    color: var(--white-text);
    text-transform: uppercase;
}

.nav__link_login {
    text-transform: initial;
    font-size: 15px;
    font-weight: 300;
}
.nav__contact{
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
}
.nav__link,
.nav__contact{
    position: relative;
    overflow: hidden;
    display: inline-block;
}
.nav__link:before, .nav__link:after,
.nav__contact:before, .nav__contact:after{
    content: '';
    position: absolute;
    transition: transform .5s ease;
}
.nav__link:before,
.nav__contact:before{
    left: -1px;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: var(--white-text);
    transform:  translateX(-100%);
}
.nav__link:hover:before,
.nav__contact:hover:before{
    transform:  translateX(0);
}
.nav__list__contacts{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding-right: 25px;
    top: 5px;

}
.nav__contact{
    color: var(--white-text);
}
.nav__contact>span{
    margin-left: 5px;
}
.header__list__info{
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    right: 0;
}
.header__info__tooltip{
    background-color: whitesmoke;
    color: var(--black-text);
    padding: 5px;
    font-size: 10px;
    font-weight: 400;
    position:absolute;
    right: 0;
    top: 18px;
    opacity: 0;
}
.header__info__tooltip__title{
    font-weight: 700;
}
.header__list__info:hover + .header__info__tooltip{
    opacity: 1;
}


.header__catalog,
.header__search__wrapper {
    width: 100%;
    padding: 10px 15px;
    color: var(--black-text) ;
    background-color: var(--white-text);
    font-weight: 800;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__catalog {
    max-width: 291px;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 16px;
    z-index: 60;
}

.header__catalog::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    pointer-events: none;
    z-index: -1;
}

.header__catalog:hover::after {
    opacity: 1;
    visibility: visible;
}

.header__search__wrapper {
    max-width: 624px;
}

.header__catalog>*{
    width:100%;
}
.header__catalog{
    padding: 0;
}
.header__search__wrapper{
    padding: 0 0 0 22px;
}
.btn__header__search{
    background-color: var(--bg-pink);
    padding: 7px 25px;
    border-radius: 0 5px 5px 0;
}
.btn__list__down{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    margin-left: auto;
}

.user__wrapper {
    position: relative;
    width: max-content;
    cursor: pointer;
    z-index: 100;
}
.user__wrapper p {
    /*font: 300 15px/1.2 'Roboto';*/
    font-size: 15px;
    font-weight: 300;
    font-family: Fixel, sans-serif;
    text-decoration: none;
    color: #fff;
    float: none;
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    transition: all 0.2s linear 0s;
}

.user__wrapper p:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0px;
    left: 0;
    bottom: -2px;
    background-color: #ffffff;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
}

.user__wrapper p:hover:after {
    width: 100%;
    height: 1px;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
}

.btn__list__down__link{
    width: 124px;
    height: 40px;
    color: #191919;
    font-size: 16px;
    font-weight: bold;
    -webkit-flex: 0 0 124px;
    flex: 0 0 124px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F1E4D3;
    border: 1px solid #F1E4D3;
    border-radius: 4px;
    margin: 0 0 0 10px;
    position: relative;
    cursor: pointer;
    -webkit-border-radius: 4px;
    transition: all .25s ease;
}

.btn__list__down__link:hover {
    background-color: #F1D7D3;
}

.cart__icon {
    margin-right: 5px;
    width: 16px;
    height: 16px;
}
.btn__list__down a{
    background-color: inherit;
    font-size: 16px;
    color: var(--white-text);
}

.cart__item {
    position: absolute;
    z-index: 2;
    width: 24px;
    height: 24px;
    background-color: #f92323;
    color: white;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    top: -9px;
    left: -10px;
    text-align: center;
    pointer-events: none;
}

.user__btn {
    padding: 16px 0;
    position: absolute;
    top: 35px;
    right: -20px;
    width: 150px;
    background: #F4F4F4;
    box-shadow: 0 5px 20px rgba(0,0,0,0.2);
    border-radius: 4px;
    z-index: 5;
}

.order__btn {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #333333;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: all 0.2s linear 0s;
}

.order__btn:hover {
    color:#BD2727;
}

.order__btn__out {
    font-weight: normal;
    font-size: 13px;
    color: #BD2727;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    cursor: pointer;
}

.header__search__label input, .header__search__label {
    width: 100%;
}

.header__search__wrapper button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 64px;
    min-width: 64px;
    background-color: #CC3366;
    border-radius: 0 4px 4px 0;
    transition: all .3s;
}

 .header__search__wrapper button:hover {
     background-color: #DF5E89;
 }

.header__search__wrapper button svg {
    width: 25px;
    height: 25px;
}

@media (max-width: 1000px) {
    .nav__list__contacts{
        padding-right: 40px;
    }

    .header__nav,
    .header__down{
        gap: 25px;
    }
    .btn__list__down{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
    }
}

@media (max-width: 992px) {
    .nav__link__list {
        margin-left: 0;
    }
    .nav__list__contacts{
        padding-right: 20px;
    }
    .nav__list__contacts a {
        font-size: 11px;
    }
}
@media (max-width: 770px) {
    .header{
        display: none;
    }
}