.btn__up{
    width: 100%;
    padding: 15px 0;
    text-transform: uppercase;
    text-align: center;
    background-color: var(--bg-black);
    color: var(--white-text);
    font-weight: 700;
}
.footer__wrapper{
    background-color: var(--black-text);
    padding: 40px 0 20px;
}
.footer{
    text-align: center;
}
.footer__logo{
    margin-bottom: 40px;
}
.footer__text{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    color: var(--white-text);
    font-size: 12px;

}
@media (max-width: 770px) {
    .footer__text{
        flex-direction: row;
    }
}