.productItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px 10px 20px;
  background-color: #fff;
  border-radius: 4px;
  transition: all 0.2s linear 0s;
  margin: 0 6px;
}

.productItem:hover {
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.productContent {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.tagList {
  position: absolute;
  z-index: 20;
}

.tagList p {
  margin: 0 5px 5px 0;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 5px;
  width: fit-content;
  line-height: 1;
}

.productImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 166px;
  margin-bottom: 10px;
  cursor: pointer;
}

.productImg img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.productCode {
  font-size: 13px;
  line-height: 16px;
  color: #949494;
  text-align: center;
}

.productTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin: 0 0 5px;
  color: #000000;
  text-decoration: none;
  max-width: 400px;
  transition: all 0.2s linear 0s;
}

.productTitle:hover {
  color: #cc3366;
}

@media (max-width: 640px) {
  .tagList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .productItem {
    flex-direction: column;
    width: 100%;
  }

  .productContent {
    width: 100%;
  }

  .productTitle {
    max-width: initial;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}
