.categoryWrapper {
    width: 100%;
    margin: 0 0 20px;
    padding: 10px 15px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.categoryWrapper > span {
    width: 100%;
    color: #333;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    padding: 0 0 18px 0;
    margin: 0 0 0 0;
    display: block;
}

.categoryWrapper ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.categoryHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.categoryItem ul {
    padding: 8px 0 0 12px;
}

.categoryWrapper > ul > .categoryItem > .categoryHead > a {
    font-size: 14px;
    color: #666;
    font-weight: 800;
    text-transform: uppercase;
    width: 100%;
    transition: all .3s;
}

.categoryWrapper > ul > .categoryItem > .categoryHead > a:hover {
    color: #CC3366;
    text-decoration: none !important;
}


.categoryItemActive > .categoryHead > a:hover {
    text-decoration: none !important;
}

.categoryItemActive > .categoryHead > a {
    color: #CC3366 !important;
    cursor: no-drop;
    text-decoration: none !important;
}

.categoryItem a {
    font-size: 14px;
    color: #666;
    font-weight: 400;
    font-style: normal;
    cursor: pointer;
    width: 100%;
}

.categoryItem a:hover {
    text-decoration: underline !important;
}

.iconOpen, .iconClosed {
    flex: 0 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 25px;
    height: 25px;
    background-color: rgba(0,0,0,0.6);
    border: none;
    border-radius: 4px;
    transition: all 0.3s;
    cursor: pointer;
    user-select: none;
}

.iconClosed > svg {
    display: block;
    transform: rotate(90deg);
}

.iconOpen > svg {
    display: block;
    transform: rotate(-90deg);
}

.iconOpen {
    background-color: #CC3366;
}