.catalog__wrapper{
    position: relative;
    padding: 8px 15px;
    z-index: 50;
}

.catalog__list{
    padding: 12px 0;
    width: 100%;
    min-width: 291px;
    position:absolute;
    z-index: -1;
    opacity: 0;
    background-color: var(--white-text);
    border-radius: 3px 0 0 3px;
    top: 49px;
    left: 0;
    display: flex;
    flex-direction: column;
    pointer-events: none;
}
.catalog__list__link{
    display:block;
    width: 100%;
    color: var(--black-text);
    font-size: 16px;
    font-weight: 400;
    position: relative;
    transition: all .3s ease-in-out;
}

.catalog__list__item > .catalog__list__link {
    text-transform: uppercase;
    padding: 9px 9px 9px 12px;
    font-size: 16px;
    line-height: 1.2;
    transition: all 0.2s linear 0s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.catalog__list__item > .catalog__list__link:hover .catalog__list__link_icon {
    background-color: #CC3366;
}

.catalog__list__link_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba(0,0,0,0.6);
    transition: 0.3s;
}

.catalog__list__link_icon svg {
    fill: #fff;
}

.btn__catalog__link{
    display:inline-block;
    width:100%;
}
.btn__catalog>a{
    color: var(--black-text);
}
.catalog__wrapper:hover > .catalog__list,
.catalog__list:hover,
.btn__catalog:hover + .catalog__list{
    z-index: 10;
    opacity: 1;
    pointer-events: initial;
}

.catalog__list::before{
    content: "";
    width: 120%;
    height: 20px;
    position: absolute;
    top: -12px;
    left: 0;

}
.category__tile{
    color: var(--black-text);
}
.catalog__list__item:hover .catalog__list__link {
    color: var(--bg-pink);
    text-decoration: underline !important;
}
.catalog__list__item:hover .catalog__list__link:after {
    background: var(--bg-pink);
}

.catalog__list__item:hover .catalog__list__link .catalog__list__item:after {
    background-color: var(--bg-pink);
    color: var(--bg-grey);
}
.catalog__list__item__category{
    position: absolute;
    width: 100vw;
    overflow: auto;
    max-width: 1011px;
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    gap: 30px;
    background-image: linear-gradient(to right, transparent 20px, whitesmoke 20px);
    opacity: 0;
    z-index: -1;
    top: 0;
    left: calc(100% - 20px);
    height: 100%;
    pointer-events: none;
}

.catalog__list__item__category__item{
    width: calc(100% / 3 - 30px);
    text-align: center;
}
.catalog__list__item:hover .catalog__list__item__category,
.catalog__list__item__category:hover {
    opacity: 1;
    z-index: 10;
    pointer-events: initial;
}

.catalog__list__item__category__item>a:hover > p{
    color: var(--bg-pink);
}
@media (max-width: 1399px) {
    .catalog__list__item__category {
        max-width: 832px;
    }
}

@media (max-width: 1199px) {
    .catalog__list__item__category {
        max-width: 650px;
        padding: 40px;
    }
}

@media (max-width: 992px) {
    .catalog__list__item__category {
        max-width: 430px;
    }
}


