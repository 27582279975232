.product__list {
    width: 100%;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(1, 1fr);
}

.productItem {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    transition: .3s;
    float: none;
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none; }
.navButtons button {
    transition: all .3s;
}
.navButtons button:hover {
    color: #CC3366;
}

@media (max-width: 480px) {
    .navButtons button span {
        display: none;
    }
}

.productContent {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.productContent__wrapper {
    width: 100%;
    padding: 0 10px;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;
}

.tagList li {
    margin: 0 5px 5px 0;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 5px;
    width: fit-content;
    line-height: 1;
}

.productImg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 166px;
    margin-bottom: 10px;
}

.productImg img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.img__wrapper__block {
    width: 210px;
    height: auto;
    margin: 0 10px 10px;

}


.article__text {
    font-size: 13px;
    line-height: 16px;
    color: #949494;
    text-align: center;
}

.productCode {
    font-size: 11px;
    line-height: 11px;
    color: #666;
    margin-top: 5px;
}

.productTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin: 0 0 5px;
    color: #000000;
    text-decoration: none;
    max-width: 400px;
    transition: all 0.2s linear 0s;
}

.productTitle:hover {
    color: #CC3366;
}

.info__wrapper {
    width: 25%;
    height: auto;
    margin-bottom: 3px;
    overflow: hidden;
    text-align: left;
}

.product__info {
    width: 100%;
    height: inherit;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    font-weight: 400;
    font-size: 16px;
}

.price {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #333333;
    padding: 0;
}

.counter__block {
    display: flex;
    width: 20%;
    min-width: 200px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ACACAC;
    margin: 0 20px 0 10px;
    align-items: center;
    justify-content: space-between;


}

.counter__block input {
    width: 120px;
    height: 38px;
    border: none;
    border-right: 1px solid #ACACAC;
    border-left: 1px solid #ACACAC;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #555555;
    padding: 0 14px;

    -webkit-border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.order__btn {
    width: 23%;
    height: 43px;
    background: #CC3366;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3);
    outline: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    top: -1px;
    margin: 0 0 0 10px;
}

.cart__icon {
    fill: #fff;
    margin-right: 5px;
    width: 20px;
    height: 20px;
}

.btn__wrapper {
    display: flex;
}

@media (max-width: 1200px) {
    .productContent__wrapper {
        flex-wrap: wrap;
    }
    .img__wrapper__block {
        width: 150px;
        height: auto;
    }
    .info__wrapper {
        min-width: 185px;
    }
    .order__btn {
        margin: 0 0 0 auto;
        min-width: 120px;
    }
}

@media (max-width: 1155px) {
    .counter__block input {
        padding: 0 10px;
        width: 120px;
    }
}

@media (max-width: 999px) {
    .info__wrapper {
        width: 100%;
    }
    .price {
        width: 100%;
        margin: 5px 0;
        display: flex;
    }
    .counter__block {
        margin: 0 12px 0 0;
    }
    .order__btn {
        margin: 10px auto 0 0;
    }
}

@media (max-width: 693px) {
    .counter__block {
        margin: 0 12px 12px 0;
    }
}

@media (max-width: 640px) {
    .tagList {
        display: flex;
        flex-wrap: wrap;
    }

    .productItem {
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
    }

    .productContent {
        width: 100%;
    }
    
    .productTitle {
        max-width: initial;
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }

    .img__wrapper__block {
        width: 100%;
        height: auto;
    }
    .price {
        justify-content: center;
    }
    .product__info {
        text-align: center;
        display: block;
    }
    .counter__block {
        margin: 0 12px 1px 0;
    }

    .productContent__wrapper {
        padding: 0 10px;
        justify-content: center;
    }
    .order__btn {
        margin:10px 0 0 0;
        min-width: 124px;
    }
}

.productTile {
    flex-direction: column;
}

.productTile .price, .productTile .info__wrapper {
    width: 100%;
    text-align: left;
}

.productTile .price {
    margin-bottom: auto;
}

.productTile .counter__block, .productTile .order__btn {
    flex: 1;
    margin: 0;
}

.productTile .order__btn {
    height: 40px;
    min-width: initial;
}

.productTile .product__info {
    display: block;
}

.productTile .productContent {
    height: 100%;
}

.productTile .productContent__wrapper {
    flex-wrap: wrap;
    height: 100%;
    gap: 5px;
}

.productTile .img__wrapper__block {
    margin: 0 auto;
}

.productTile .counter__block {
    min-width: 140px;
}

.productTile .counter__block input {
    width: 100%;
}

.productTile .btn__minus, .productTile .btn__plus {
    min-width: 38px;
}

.product__listTile {
    grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1199.9px) {
    .product__listTile {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 991.9px) {
    .product__listTile {
        grid-template-columns: repeat(1, 1fr);
    }
}