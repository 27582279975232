.form__section {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  min-height: 960px;
}

.link__block {
  display: flex;
  width: 100%;
  margin: 0 0 8px 0;
  color: #737373;
  font: 300 15px/24px 'Roboto';
}

.link__block a {
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s linear 0s;
}

.link__block a.activeLink {
  color: #acacac;
}

.link__block a.inactiveLink {
  color: #111;
}

.link__block b {
  position: relative;
  float: left;
  font-weight: 400;
  padding: 0 6px;
}

.login__block,
.recovery__block {
  display: flex;
  width: 100%;
  padding: 20px 45px;
  max-width: 1000px;
  margin: 0 auto;
  background: #fff;
  border-radius: 4px;
}

.login__block__form,
.recovery__block__form {
  width: 50%;
  padding: 0 4% 0 0;
  border-left: 1px solid #fff;
}

.login__block__registration,
.recovery__block__registration {
  width: 50%;
  border-left: 1px solid #f2f2f2;
  padding: 0 0 0 4%;
  align-self: flex-start;
}

.login__block__title,
.recovery__block__title {
  color: #111;
  font: 700 18px/30px 'Fixel';
  font-family: Fixel, sans-serif;
  text-transform: uppercase;
  margin: 0 0 20px;
  text-align: center;
}

.login__block__info {
  display: flex;
  flex-direction: column;
}

.recovery__block__info {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 8px;
}

.recovery__block__info a {
  color: #777777;
  text-decoration: underline !important;
  transition: all 0.2s linear 0s;
  text-align: center;
}
.recovery__block__info a:hover {
  text-decoration: underline !important;
  color: #111;
  transition: all 0.2s linear 0s;
}

.recovery__block__info p {
  font-size: 17px;
  font-weight: 300;
  line-height: 1.59;
  color: #7c7c7c;
  text-align: center;
}

.login__block__text {
  font-size: 17px;
  font-weight: 300;
  line-height: 1.59;
  color: #7c7c7c;
}

.login__form,
.recovery__form {
  width: 100%;
}

.login__form .login__form__item,
.recovery__form .recovery__form__item {
  margin: 0 0 25px 0;
  text-align: center;
}

.login__form__item,
.recovery__form__item {
  position: relative;
  float: left;
  width: 100%;
}

.login__form label,
.recovery__form label {
  position: absolute;
  top: -10px;
  left: 90px;
  z-index: 11;
  font-size: 16px;
  color: #0645ad;
  pointer-events: none;
  padding: 0px 15px;
  font-size: 10px;
  background-color: #ffffff;
  transition: all 0.5s ease;
}

.login__form__input,
.recovery__form__input {
  width: 258px;
  float: none;
  height: 41px;
  border: 1px solid #bfbfbf;
  background: #fff;
  color: #1b0600;
  border-radius: 4px;
  padding: 0 23px;
  transition: all 0.2s linear 0.2s;
}

.recovery__form__input__captcha {
  width: 258px;
  float: none;
  height: 41px;
  border: 1px solid #bfbfbf;
  background: #fff;
  color: #1b0600;
  border-radius: 4px;
  padding: 0 23px 0 120px;
  transition: all 0.2s linear 0.2s;
}

.login__form__button {
  width: 258px;
  height: 43px;
  font-size: 20px;
  font-weight: 900;
  font-family: Fixel, sans-serif;
  border: none;
  border-radius: 4px;
  background-color: #bb0d47;
  color: #fff;
  font-size: 14px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  transition: 0.3s;
}

.login__form__button:hover {
  background-color: #df5e89;
  color: #fff;
}

.login__form__link {
  color: #777777;
  text-decoration: underline !important;
  transition: all 0.2s linear 0s;
}

.recovery__captcha {
  position: absolute;
  top: 5px;
  left: 86px;
}
.recovery__captcha__img {
  height: 29px;
}

.error {
  border: 1px solid #b30000;
  transition: all 0.2s linear 0.2s;
}

/*Media -------------- Rules*/

@media (max-width: 678px) {
  .login__block,
  .recovery__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
  }
  .login__block__form,
  .login__block__registration,
  .recovery__block__form,
  .recovery__block__registration {
    width: 100%;
    padding: 0;
  }
  .login__form input,
  .recovery__form__input,
  .recovery__form__input__captcha {
    width: 100%;
  }

  .login__block__registration,
  .recovery__block__registration {
    border: none;
  }
  .login__form__button,
  .recovery__form__button {
    width: 100%;
  }
}

@media (min-width: 320px) {
  .login__form label,
  .recovery__form label {
    left: 10px;
  }
  .recovery__captcha {
    left: 10px;
  }
}

@media (min-width: 576px) {
  .login__form label,
  .recovery__form label,
  .recovery__captcha {
    left: 25px;
  }
}

@media (min-width: 768px) {
  .login__form label,
  .recovery__form label,
  .recovery__captcha {
    left: 20px;
  }
}
@media (min-width: 992px) {
  .login__form label,
  .recovery__form label,
  .recovery__captcha {
    left: 75px;
  }
}

@media (min-width: 1200px) {
  .login__form label,
  .recovery__form label,
  .recovery__captcha {
    left: 90px;
  }
}
