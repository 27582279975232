.filterSortWrapper {
    display: flex;
    align-items: center;
}

.title {
    color: #333;
    font-size: 12px;
    font-weight: 600;
}

.dropDownContainer {
    position: relative;
}

.selectedSort {
    width: 196px;
    color: #acacac;
    font-size: 12px;
    font-weight: 300;
    padding: 0 23px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    z-index: 2;
}

.selectedSort::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin-right: -8px;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #1b0600 transparent transparent transparent;
}

.sortList {
    position: absolute;
    top: 61px;
    right: -10px;
    left: -1px;
    background: #fff;
    z-index: 4;
    padding: 0;
    margin: 0;
    border-top: none;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%);
}

.sortList::before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    background-color: #fff;
    transform: rotate(-44deg);
    top: -8px;
    right: 31px;
    z-index: -1;
}

.sortList li {
    display: block;
    width: 100%;
    text-decoration: none;
    padding: 8px 14px;
    font-size: 13px;
    font-weight: normal;
    color: #333333;
    font-style: normal;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;
    transition: all 0.2s linear 0s;
}

.sortList li:hover {
    color: #DE1E65;
}

@media (max-width: 640px) {
     .filterSortWrapper {
         width: 100%;
         margin: 0 0 20px;
         background-color: #fff;
         box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 5px 10px;
         border-radius: 4px;
     }

    .dropDownContainer {
        width: 100%;
    }

    .selectedSort {
        width: 100%;
    }
}