.backdrop {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 401;
  background-color: rgb(1,1,1);
  opacity: 0.75;
}

.zoom__window {
  top: 20px;
  display: block;
  max-width: 700px;
  padding: 35px 8px 8px 8px;

  min-height: 200px;
  background: #fff;
  position: absolute;
  z-index: 1002;
  margin: 0 auto;
  right: 0;
  left: 0;
  /*display: none;*/
}

.close__btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  z-index: 1;
  filter: grayscale(1);
  cursor: pointer;
  transition: all 0.2s linear 0s;
}

.close__btn img {
  width: 30px;
  height: 30px;
}

.zoom__place {
  width: 100%;
  text-align: center;
}

.zoom__place img {
  max-width: 100%;
}

@media (max-width: 850px) {
  .zoom__window {
    max-width: 600px;
  }
}

@media (max-width: 639px) {
  .zoom__window {
    max-width: 90%;
  }
  .zoom__place {
    height: auto;
  }
}