.single__product__section {
  width: 100%;
  padding-top: 10px;
  margin: 0 0 20px 0;
}

.link__block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 8px 0;
  color: #737373;
  font-size: 15px;
  font-weight: 300;
  font-family: Fixel, sans-serif;
}

.link__block a {
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s linear 0s;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none; }
.link__block a.activeLink {
  color: #acacac;
}

.link__block a.inactiveLink {
  color: #111;
}

.link__block b {
  position: relative;
  float: left;
  font-weight: 400;
  padding: 0 6px;
}

.product__block {
  width: 100%;
  margin: 0 0 20px 0;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.product__visual {
  width: 100%;
  min-height: 1px;
  margin: 0;
}

.product__visual__wrapper {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin: 14px 0 14px 0;
  display: flex;
}

.visual__wrapper {
  width: 100%;
  height: 100%;
}
.visual__wrapper img {
  width: 100%;
  height: 100%;
}

.tags__wrapper {
  position: absolute;

  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.tag {
  text-align: center;
  padding: 4px 5px;
  text-transform: capitalize;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  font-family: Fixel, sans-serif;
  color: var(--white-text);
}

.product__info {
  flex: 1;
  padding: 20px 50px 20px 20px;
}

.product__info__first,
.product__info__second {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product__info__second {
  margin: 20px 0 0 0;
}

.product__info__title {
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  font-family: Fixel, sans-serif;
  color: #333333;
  line-height: 1.14;
}

.product__info__article {
  width: 100%;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
  font-family: Fixel, sans-serif;
  margin: 10px 0 0 0;
  padding: 0;
}

.product__info__description {
  margin: 20px 0;
}

.product__info__ds__title {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Fixel', sans-serif;
  margin: 0 0 4px 0;
  width: 100%;
}

.product__info__ds__content {
  font-size: 15px;
  font-family: 'Fixel', sans-serif;
  color: #777;
}

.product__info__row {
  width: 100%;
  display: table;
  box-sizing: border-box;
  padding: 5px 10px;
}

.product__info__text {
  display: table-cell;
  width: 40%;
  color: #777777;
  font-size: 15px;
  font-weight: 700;
  font-family: Fixel, sans-serif;
  vertical-align: middle;
}

.product__info__text:nth-child(2) {
  font-weight: normal;
}

.product__info__row:nth-child(odd) {
  background-color: #f3f3f3;
}

.counter__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  gap:20px;

}

.counter__wrapper .cart__total {
  width: auto;
}

.cart__total {
  display: flex;
  width: 23%;
  min-width: 200px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ACACAC;
  margin: 0;
  align-items: center;
  justify-content: space-between;
}

.cart__total input {
  width: 120px;
  height: 38px;
  border: none;
  border-right: 1px solid #ACACAC;
  border-left: 1px solid #ACACAC;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #555555;
  border-radius: 0;
  padding: 0 14px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.order__wrapper {
   flex: 0 1 50%;
  margin: 0;
  display: flex;
}

.product__visual {
  width: 500px;
  min-height: 1px;
  margin: 0 60px 0 0;
}

.order__btn {
  height: 40px;
  padding: 0 16px;
  width: 190px;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  background: #CC3366;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3);
  outline: none;
  transition: all 0.2s linear 0s;
}

.order__btn:hover {
  background: #DF5E89;
  color: white;
}

.cart__icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

/*Media -------------- Rules*/

@media (max-width: 1200px) {
  .product__visual {
    margin-right: 25px;
    width: 400px;
  }
  .order__btn {
    width: auto;
  }

}

@media (max-width: 1155px) {
  .cart__total {
    min-width: 200px;
  }
  .cart__total input {
    padding: 0 10px;
    width: 120px;
  }
}

@media (max-width: 999px) {
  .product__visual {
    width: 100%;
    margin-right: 0;
  }
  .product__visual__wrapper {
    width: 100%;
    justify-content: center;
  }
  .product__info {
    float: right;
    margin: 20px 0 0 0;
    width: 100%;
    flex: auto;
    padding: 10px;
  }
  .cart__total {
    margin: 0 12px 0 0;
  }

}

@media (max-width: 748px) {
  .cart__total {
    margin: 0 12px 12px 0;
  }
}


@media (max-width: 639px) {
  .product__visual {
    padding: 0;
    width: 100%;
    left: 0;
    margin: 0;
  }
  .product__info {
    width: 100%;
    padding: 15px;
  }
  .product__visual__wrapper {
    margin-bottom: 20px;
  }

  .counter__wrapper {
    margin: 15px 0 0 0;
  }

  .order__wrapper {
    flex: 1;
    margin: 0;
  }
  .cart__total {
    margin: 0 12px 1px 0;
  }
  .counter__wrapper .cart__total {
    flex: 1;
    width: 100%;
    margin: 0;
  }
  .order__btn {
    width: 100%;
  }

}

