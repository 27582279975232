.order__section {
  /*height: 100vh;*/
  background: #f8f8f8;
  width: 100%;
  flex: 1;
  padding-top: 10px;
}

.order__block {
  width: 100%;
}

/*.link__wrapper {*/
/*  padding: 20px 0;*/
/*  margin: 0 0 11px 0;*/
/*}*/

.order__home__link {
  color: #acacac;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.2s linear 0s;
}

.order__link {
  width: 100%;
  background: #f3f3f3;
  padding: 20px 0 20px 30px;
  margin: 0 0 11px 0;
  text-decoration: underline;
  color: #111;

  transition: all 0.2s linear 0s;
  font-family: Fixel, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

}

.order__head {
  width: 100%;
  height: 54px;
  background: #111;
  display: flex;
  margin-bottom: 5px
}

.order__cell__number,
.order__cell__date,
.order__cell__sum,
.order__cell__details {
  padding: 16px 0 0 0;
  text-align: center;
  color: #fff;
  font-family: Fixel, sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.order__cell__number {
  width: 143px;
}
.order__cell__date,
.order__cell__sum {
  width: 169px;
}
.order__cell__details {
  width: 318px;
}

.order__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.order__item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  height: 52px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
  background-color: #ffffff;
}

.item__number,
.item__date,
.item__sum {
  height: 36px;
  /*padding: 16px 0 0 0;*/
  color: #777777;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
}

.item__btn {
  width: 318px;
  display: flex;
  justify-content: center;
  font-family: Fixel, sans-serif;
  font-size: 12px;

  text-decoration: underline;
  color: #383838;
}

.item__number {
  /*justify-content: center;*/
  width: 141px;
}
.item__date,
.item__sum {
  width: 169px;
}

.order__details {
  width: 100%;
  height: auto;
  border: 1px solid #e7e7e7;
  background: #fff;
  margin: -15px 0 32px 0;
  padding: 20px 30px;
  overflow: hidden;
  transition: all 0.2s linear 0s;
}

.details__list {
  width: 100%;
  margin: 0 30px 0 0;
  display: flex;
  flex-wrap: wrap;
}

.details__item {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.product__photo,
.product__photo img {
  max-width: 50px;
}

.product__info {
  margin: 0 40px;
  color: #111;
  font-weight: 600;
}

.product__info p,
.order__total__info {
  font-weight: 600;
  font-size: 17px;
}

.product__link {
  font-size: 17px;
  text-decoration: none;
  font-weight: 300;
  color: #111;
  cursor: pointer;
  outline: none;
}

@media (max-width: 999px) {
  .order__link {
    text-align: center;
    float: none;
    display: flex;
    justify-content: left;
    padding: 20px 10px;
    font-size: 16px;
  }
}

@media (max-width: 771px) {
  .order__link {
    width: 100%;
    display: flex;
    justify-content: left;
    font-size: 14px;
  }
  .order__cell__number,
  .order__cell__date,
  .order__cell__sum,
  .order__cell__details {
    font-size: 14px;
  }

}

@media (max-width: 687px) {
  .item__number,
  .item__date,
  .item__sum {
    font-size: 12px;
  }
}


@media (max-width: 639px) {
  .order__cell__number {
    width: 100%;
    font-size: 12px;
  }
  .order__cell__date,
  .order__cell__sum,
  .order__cell__details {
    display: none;
  }
  .item__number {
    width: 10%;
  }
  .item__date,
  .item__sum {
    width: auto;
    /*width: 100%;*/
    margin: 0 5px;
  }
  .item__number,
  .item__date,
  .item__sum {
    font-size: 12px;
  }
  .item__btn {
    position: absolute;
    right: 10px;
    justify-content: flex-end;
    margin: 0 20px 0 auto;
  }
  .item__btn {
    justify-content: flex-end;
    margin: 0 5px 0 auto;
  }
  .order__details {
    padding: 15px 10px;
  }
  .details__item {
    flex-wrap: wrap;
    border-bottom: 1px solid grey;
    padding-bottom: 10px;
  }
  .product__info {
    margin: 0 10px;
  }
  .product__photo {
    margin: 0 auto 5px;
  }
}
