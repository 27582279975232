.swiper__section {
  width: 100%;
  margin: 0 0 50px 0;
}

.swiper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.swiper__wrapper {
  background-color: #fff;
  padding: 20px;
  display: flow-root;
}

.swiper__title {
  color: #111;
  font-size: 20px;
  font-weight: 700;
  font-family: Fixel, sans-serif;

  text-transform: uppercase;
  margin: 0 0 30px 0;
  width: 100%;
  text-align: left;
}

.slide {
  width: 230px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;
  margin: 0;
}

.swiper-wrapper {
  box-sizing: border-box;
}
.swiper-slide {
  height: auto;
}
.swiper-pagination {
  position: relative;
  left: 0;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
  top: -35px;
  left: 0;
  width: 100%;
}
.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: #999999;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  line-height: 1;
  padding: 15px 20px;
  font-size: 12px;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  width: 35px;
  height: 35px;
  top: calc(50% - 10px);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-pink);
  color: white;
  transition: all 0.3s ease-in-out;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: var(--black-text);
  opacity: 0.5;
}
.swiper-button-prev {
  left: -2px;
  border-radius: 0 7px 7px 0;
}
.swiper-button-next {
  right: -2px;
  border-radius: 7px 0 0 7px;
}

@media (max-width: 770px) {
  .container {
    max-width: 100%;
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #999999;
  }
}
